$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'sm-tablet': 992px,
  'desktop': 1024px,
  'widescreen': 1200px,
  'largescreen': 1500px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';
@import 'vendors/normalize';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';

@import 'vendors/icomoon';

@import 'layout/common';
@import 'layout/header';

@import 'components/banner';
@import 'layout/general';

@import 'layout/sidebar';

@import 'components/info-block';
@import 'components/description-block';

@import 'pages/subpage';
@import 'pages/product-page';

@import 'layout/footer';