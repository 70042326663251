.banner {
  position: relative;
  text-align: center;
  padding: 40px 0;

  @include media('>=tablet') {
    padding: 123px 0 60px;
    min-height: 550px;
  }

  @include media('>=desktop') {
    min-height: 938px;
  }
}

.banner-text {
  background: rgba($blue, 0.5);
  font-size: 18px;
  line-height: 1.5;
  color: $white;
  text-align: left;
  padding: 20px 15px;
  margin: 0 -20px;

  @include media('>=tablet') {
    font-size: 24px;
    padding: 26px 36px 23px;
    max-width: 580px;
    margin: 0 auto;
  }

  h1 {
    font-size: 35px;
    line-height: 1.3;
    font-weight: normal;
    margin: 0 0 13px;
    color: $white;

    @include media('>=tablet') {
      font-size: 50px;
      margin-bottom: 19px;
    }
  }

  p {
    margin-bottom: 35px;
  }

  .button {
    margin: 0 0 20px 4px;
  }
}

.arrow-down {
  position: absolute;
  left: 50%;
  bottom: -5px;
  color: $white;
  z-index: 99;
  text-decoration: none;
  font-size: 50px;
  line-height: 1;
  transform: translateX(-50%);

  @include media('>=tablet') {
    font-size: 60px;
    bottom: 12px;
  }

  span {
    display: block;
    line-height: 1;
    font-size: 60px;
  }
}