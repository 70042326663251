.product-about {
  table {
    margin-bottom:22px;
  }

  @include media('>=tablet') {
    h3 {
      float: left;
      color: #fff;
      background-color: #005292;
      line-height: 1.6;
      font-size: 15px;
      padding: 9px 18px 8px;
      margin: 0;
      width: 190px;
      clear: both;
    }

    table {
      margin-bottom: 41px;
    }
  }
}