.info-wrapper {
  position: relative;
  padding: 20px 0;
  max-width: 1900px;
  margin: 0 auto;

  @include media('>=tablet') {
    min-height: 680px;
    padding: 102px 0 50px;
  }
}

.info-text {
  margin: 0 -20px;
  color: $white;
  background: rgba($blue, 0.8);
  padding: 20px 15px;
  text-align: center;

  @include media('>=tablet') {
    width: 519px;
    padding: 42px 53px;
    border-radius: 0 0 0 5px;
    margin: 0;
    text-align: left;
  }

  h3 {
    font-size: 26px;
    line-height: 1.22;
    font-weight: normal;
    margin: 0 0 40px;
    color: $white;

    @include media('>=tablet') {
      font-size: 32px;
      line-height: 1.375;
    }
  }

  .button {
    margin-bottom: 20px;

    @include media('>=phone') {
      margin: 0 30px 20px;
    }

    @include media('>=tablet') {
      margin: 0 0 20px;
    }
  }
}