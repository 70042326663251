.sidebar {
  padding: 0 20px;

  @include media('>=tablet') {
    float: left;
    width: 30%;
    padding: 1px 15px 0;
  }

  @include media('>=desktop') {
    width: 20%;
  }

  .title {
    display: block;
    font-size: 18px;
    color: #333;
    font-weight: normal;
    margin-bottom: 18px;

    @include media('>=tablet') {
      letter-spacing: 0.06em;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    a {
      color: #000;
      text-decoration: none;
      display: inline-block;
      vertical-align: top;

      @include media('>=tablet') {
        pointer-events: none;
      }
    }

    .nav-button {
      float: right;
      height: 17px;
      width: 20px;
      margin: 5px 0 0 12px;

      @include media('>=tablet') {
        display: none;
      }
    }
  }
}

.aside-menu-active {
  .nav-button {
    span {
      opacity: 0;
    }

    &:before,
    &:after {
      top: 50%;
      bottom: auto;
      left: 2%;
      right: 2%;
      transform: rotate(45deg);
      background: #666;
      margin-top: -1px;
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}

.aside-menu-wrapper {
  position: relative;
}

.menu-hold {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 100%;
  width: 280px;
  background: $blue;
  margin-top: 15px;
  padding: 13px 19px 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s;

  @include media('>=tablet') {
    position: static;
    transform: none;
    margin: 0;
    opacity: 1;
    padding: 0;
    visibility: visible;
    width: auto;
    background: transparent;
  }

  .aside-menu-active & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.aside-menu {
  @extend %listreset;
  font-size: 16px;
  font-weight: 700;

  >li {
    margin-bottom: 12px;
  }

  a {
    text-decoration: none;
    transition: color 0.3s;
    color: $white;

    @include media('>=tablet') {
      color: $light-blue;
    }

    &:hover {
      color: #b0d7ed;

      @include media('>=tablet') {
        color: #333;
      }
    }
  }

  ul {
    @extend %listreset;
    font-size: 15px;
    font-weight: normal;
    padding: 6px 0 0;

    li {
      margin: 0 0 6px;

      @include media('>=tablet') {
        margin: 0 0 -2px;
      }
    }

    a {
      display: inline-block;
      vertical-align: top;
      position: relative;
      padding-left: 15px;

      &:before {
        content: '';
        @include size(4px);
        background: #ccc;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 10px;
        transition: background 0.3s;
      }

      &:hover {
        &:before {
          background: #80a9c9;
        }
      }
    }
  }

  .active {
    >a {
      color: #b0d7ed;

      @include media('>=tablet') {
        color: #333;
      }
    }
  }
}