/**
 * Fix Piknik topbar visibility.
 * Esteri header will otherwise float on top of it.
 */
#sl-piknik {
  & > .navbar-fixed-top {
    z-index: 99999 !important;
  }

  & ~ #wrapper > .header {
    margin-top:40px;
  }
}

#wrapper {
  /*margin-top: 40px;*/
}