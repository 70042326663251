.description-block {
  text-align: center;
  padding: 40px 0;

  @include media('>=tablet') {
    padding: 92px 0 70px;
  }

  h2 {
    font-weight: normal;
    font-size: 26px;
    margin: 0 0 40px;
    color: $black;

    @include media('>=tablet') {
      font-size: 36px;
      margin-bottom: 0;
    }
  }
}

.description-wrapper {
  font-size: 0;
  letter-spacing: -4px;
  margin: 0 -15px;

  @include media('>=widescreen') {
    margin: 0 -60px;
  }

  .column {
    padding: 0 15px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: $font-size-base;
    letter-spacing: 0;
    text-align: left;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=desktop') {
      width: 33.33%;
    }
  }
}

.article {
  max-width: 315px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 auto 40px;
  text-align: center;

  @include media('>=tablet') {
    margin-bottom: 60px;
    text-align: left;
  }

  .image {
    text-align: center;
    white-space: nowrap;
    margin-bottom: 15px;

    @include media('>=tablet') {
      height: 285px;
      margin-bottom: 5px;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    img {
      display: inline-block;
      vertical-align: middle;

      @include media('>=tablet') {
        width: 50%;
      }

      @include media('>=desktop') {
        width: auto;
      }
    }
  }

  p {
    margin-bottom: 24px;
  }
}

.list-wrapper {
  @extend %clearfix;
  margin: -3px -10px 0;

  .text-list {
    padding: 0 15px;
    float: left;
    width: 50%;

    @include media('>=tablet') {
      width: 40%;
    }

    &:first-child {
      @include media('>=tablet') {
        width: 60%;
      }
    }
  }
}

.text-list {
  @extend %listreset;

  li {
    margin-bottom: 4px;
  }

  a {
    text-decoration: none;
  }
}