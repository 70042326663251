.product-holder {
	padding: 0 0 30px;

	@include media('>=tablet') {
		padding: 24px 0;
	}

	.head {
		overflow: hidden;
		padding-bottom: 15px;
		position: relative;

		@include media('>=tablet') {
			padding-bottom: 33px;
		}
	}

	h1 {
		padding-right: 33px;

		@include media('>=tablet') {
			float: left;
		}
	}

	.img-list {
		position: absolute;
		right: 0;
		top: 0;
		margin: 4px 0 0;
		z-index: 3;

		@include media('>=tablet') {
			position: static;
			float: right;
			margin: 3px 2px 0 0;
		}
	}
}

.img-list {
	@extend %listreset;

	li {
		float: left;
		margin-left: 10px;
		width: 32px;

		@include media('>=tablet') {
			width: 39px;
		}
	}
}

.link-back {
	font-size: 14px;
	margin-bottom: 17px;

	@include media('>=tablet') {
		margin: 0 0 28px;
	}

	a {
		color: #666;
		display: inline-block;
		vertical-align: top;
		text-decoration: none;
	}
}

.img-slider {
	padding-bottom: 46px;
	position: relative;

	.arrow-next,
	.arrow-prev {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		margin-left: -28px;
		background: transparent;
		padding: 0;
		font-size: 25px;
		line-height: 1;
		border: none;
		color: $blue;
		text-decoration: none;
		transition: color 0.3s;

		@include media('>=tablet') {
			margin-left: -41px;
		}

		&:hover {
			color: $green;
		}

		&.arrow-prev {
			margin-left: 28px;

			@include media('>=tablet') {
				margin-left: 15px;
			}
		}
	}
}

.product-detail {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px 5px;
	font-size: 16px;
	line-height: 1.63;

	@include media('>=tablet') {
		margin-bottom: 67px;
	}

	.holder {
		padding: 0 20px;

		@include media('>=desktop') {
			width: 69%;
		}

		@include media('>=widescreen') {
			width: 75.3%;
		}
	}

	.image-holder {
		width: 260px;
		margin: 0 auto 20px;

		@include media('>=tablet') {
			float: left;
			margin: 6px 0 0;
		}
	}

	.description-wrap {
		overflow: hidden;
		padding: 7px 0 0;

		@include media('>=tablet') {
			padding: 0 0 0 39px;
		}
	}

	p {
		margin-bottom: 26px;
	}

	.button-holder {
		padding-top: 6px;
		text-align: center;

		@include media('>=tablet') {
			padding-top: 22px;
			text-align: left;
		}
	}

	.button {
		padding: 10px 26px;
		margin: 0 0 14px;
		min-width: 280px;

		@include media('>=tablet') {
			min-width: 1px;
			margin: 0 10px 0 0;
		}
	}
}

.aside {
	padding: 7px 20px 0;
	display: none;

	&.for-mobile {
		display: block;
		width: auto;
		margin: 0 -20px;
		padding: 18px 0 0;

		.aside-info {
			padding: 4px 20px 0;
		}
	}

	@include media('>=desktop') {
		display: block;
		width: 31%;

		&.for-mobile {
			display: none;
		}
	}

	@include media('>=widescreen') {
		width: 24.7%;
	}
}

.list-box {
	@extend %listreset;
	color: $white;
	padding: 13px 13px 15px;
	font-size: 18px;
	background: $blue;
	margin-bottom: 26px;

	li {
		position: relative;
		padding-left: 20px;
		font-style: italic;
		font-weight: 300;

		&:before {
			@include size(4px);
			content: '';
			position: absolute;
			left: 7px;
			top: 12px;
			background: #99bad3;
			border-radius: 100%;
		}
	}
}

.aside-info {
	&:hover {
		color: $black;

		a {
			color: $black;
		}

		.ico-help {
			border-color: $green;
			background: $green;
			color: $white;
		}
	}

	.ico-help {
		@include size(40px);
		margin: 0;
		display: block;
		font-size: 26px;
		line-height: 40px;
		transition: all 0.3s;
	}

	.icon {
		float: left;
	}

	.text {
		overflow: hidden;
		padding: 3px 0 10px 14px;
	}

	.title {
		display: block;
		font-size: 20px;
		margin-bottom: 5px;
	}

	a {
		text-decoration: none;
	}
}

.nav-list {
	@extend %listreset;
	border-bottom: 1px solid #65a9de;
	font-size: 16px;
	padding: 0 0 11px;
	display: none;

	@include media('>=tablet') {
		display: block;
	}

	li {
		display: inline-block;
		vertical-align: top;
		text-transform: uppercase;
		margin: 0 28px 0 0;
	}

	a {
		color: $black;
		text-decoration: none;

		&:hover {
			color: $green;
		}
	}

	.active {
		>a {
			font-weight: 700;
		}
	}
}

.description-text-holder {
	padding: 14px 0 10px;

	@include media('>=tablet') {
		padding: 31px 0 10px;
	}

	.column-holder {
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -20px;
	}

	.left-column,
	.right-column {
		padding: 0 20px;
		width: 100%;
	}

	.left-column {
		@include media('>=tablet') {
			width: calc(100% - 380px);
		}
	}

	.right-column {
		width: 340px;
		padding-top: 5px;
		display: none;

		@include media('>=tablet') {
			display: block;
		}
	}

	.widget {
		margin-bottom: 20px;
	}

	h2 {
		margin-bottom: 21px;

		@include media('>=tablet') {
			margin-bottom: 24px;
		}
	}

	h3 {
		font-size: 20px;
		padding-top: 10px;
		margin-bottom: 26px;
	}

	p {
		margin-bottom: 24px;
	}
}

.tab-wrapper {
	.tab-pane {
		margin-bottom: 1px;
	}

	.active {
		>.title {
			>a {
				background: #519DB7;
			}

			.arrow {
				transform: rotate(180deg);
			}
		}
	}

	.title {
		text-transform: uppercase;
		margin: 0 -20px;

		@include media('>=tablet') {
			display: none;
		}

		>a {
			display: block;
			background: $blue;
			padding: 8px 20px;
			color: $white;
			text-decoration: none;
			transition: background 0.3s;

			&:hover {
				background: #519DB7;
			}
		}

		.arrow {
			float: right;
			transition: transform 0.3s;
		}
	}

	.js-acc-hidden {
		@include media('>=tablet') {
			position: static !important;
			width: auto !important;
		}
	}
}

.product-tile {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	padding: 20px 0;

	@include media('>=widescreen') {
		margin: 0 -23px;
	}

	.column {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: 0 10px;

		@include media('>=phone') {
			width: 50%;
		}

		@include media('>=tablet') {
			width: 50%;
		}

		@include media('>=desktop') {
			width: 25%;
		}

		@include media('>=widescreen') {
			padding: 0 23px;
		}
	}
}

.product-card {
	border: 1px solid #ccc;
	text-align: center;
	padding: 20px 18px;
	width: 100%;
	margin: 0 0 30px;

	@include media('>=widescreen') {
		padding: 20px 28px;
	}

	.img {
		margin-bottom: 28px;
	}

	.product-title {
		font-size: 18px;
		line-height: 1.2;
		font-weight: 700;
		display: block;
		min-height: 62px;
		padding-bottom: 3px;

		a {
			text-decoration: none;
		}
	}

	.button {
		width: 100%;
	}
}

.product-about {
	padding: 27px 0 0;

	h3 {
		font-size: 20px;
		margin: 0 0 28px;
	}
}

.small-table {
	width: 100%;
	max-width: 436px;
	margin-bottom: 35px;

	tr {
		background: #e5edf4;

		&:nth-child(2n) {
			background: transparent;
		}
	}

	td {
		padding: 9px 18px 8px;

		&:first-child {
			font-weight: 700;
			width: 140px;

			@include media('>=tablet') {
				width: 190px;
			}
		}
	}
}

.contact-wrapper {
	padding: 8px 0 0;
	flex-wrap: wrap;
	display: flex;
	justify-content: space-between;
	margin: 0 -20px;

	@include media('>=tablet') {
		padding: 8px 0 56px;
	}

	.form,
	.text-column {
		padding: 0 20px;
		width: 100%;
	}

	.form {
		@include media('>=desktop') {
			width: 55.9%;
		}
	}

	.text-column {
		@include media('>=desktop') {
			width: 41.8%;
		}
	}

	.article {
		max-width: none;

		@include media('<tablet') {
			margin-bottom: 0;
		}

		.image {
			float: left;
			margin: -17px 21px 0 -4px;
			display: none;

			@include media('>=tablet') {
				display: block;
			}

			@include media('>=desktop') {
				display: none;
			}

			@include media('>=widescreen') {
				display: block;
			}
		}

		.text-hold {
			overflow: hidden;
			padding: 34px 0 0;

			@include media('>=tablet') {
				max-width: 420px;
			}

			p {
				@include media('<tablet') {
					margin-bottom: 19px;
				}
			}

			@include media('>=tablet') {
				padding-top: 20px;
			}
		}
	}

	@include media('<tablet') {
		.list-wrapper {
			margin: 0;

			.text-list {
				float: none;
				padding: 0;
				width: auto;
				display: inline;

				li {
					display: inline;
				}

				a {
					display: inline-block;
					vertical-align: top;
					background: $blue;
					color: $white;
					border-radius: 20px;
					text-align: center;
					min-width: 280px;
					margin: 0 0 10px;
					padding: 8px 15px;
					transition: background 0.3s;

					&:hover {
						background: $green;
					}
				}
			}
		}
	}
}