.inner-banner {
  position: relative;
  height: 100px;

  @include media('>=tablet') {
    height: 230px;
  }

  &.style-2 {
    height: 100px;
    display: none;

    @include media('>=tablet') {
      display: block;
    }

    .bg-img {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($blue, 0.5);
      }
    }
  }
}

.main-section {
  padding: 23px 0;

  @include media('>=tablet') {
    padding: 63px 0 70px;
  }
}

.contact-description {
  @extend %clearfix;
  padding: 32px 0 20px;
  font-size: 16px;

  .text-hold {
    @include media('>=desktop') {
      float: left;
      width: calc(100% - 420px);
      padding: 6px 30px 0 0;
    }

    @include media('>=widescreen') {
      width: calc(100% - 510px);
    }
  }

  .map-wrap {
    padding-top: 10px;
    text-align: center;

    @include media('>=desktop') {
      float: right;
      width: 420px;
      padding-top: 9px;
    }

    @include media('>=widescreen') {
      width: 510px;
    }
  }

  .text {
    display: block;
  }

  .top-space {
    margin-top: 20px;
  }

  h2 {
    margin-bottom: 23px;
  }

  a {
    text-decoration: none;
  }

  .phone {
    color: $black;
  }

  .btn-hold {
    padding-top: 31px;

    .hold {
      margin-bottom: 11px;
    }
  }
}

.person-wrapper {
  padding: 15px 0 17px;
  max-width: 770px;

  &.add {
    max-width: 835px;

    .person-row {
      max-width: 818px;
    }
  }

  h2 {
    margin-bottom: 42px;
  }

  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
}

.person-row {
  letter-spacing: -4px;
  font-size: 0;
  margin: 0 -25px -5px;

  .column {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 0 25px;
    font-size: $font-size-base;
    letter-spacing: 0;

    @include media('>=desktop') {
      width: 50%;
    }
  }
}

.person {
  @extend %clearfix;
  background: #f2f6f9;
  line-height: 1.4667;
  margin-bottom: 20px;

  @include media('>=tablet') {
    margin-bottom: 50px;
  }

  .image {
    float: left;
    max-width: 100px;

    @include media('>=tablet') {
      max-width: 120px;
    }
  }

  .text-wrap {
    overflow: hidden;
    padding: 15px 10px;

    @include media('>=tablet') {
      padding: 15px 20px;
    }
  }

  .text {
    display: block;
  }

  .name {
    font-weight: 700;
    margin-bottom: 14px;
  }

  a {
    text-decoration: none;
    word-wrap: break-word;
  }

  .phone {
    color: $black;
  }
}

.contact-detail-wrap {
  margin: 0 -15px;
  font-size: 0;
  letter-spacing: -4px;
  padding-top: 2px;
  text-align: center;

  .column {
    display: inline-block;
    vertical-align: top;
    font-size: $font-size-base;
    letter-spacing: 0;
    padding: 0 15px;
    text-align: left;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=desktop') {
      width: 33.33%;
    }
  }
}

.contact-wrap {
  line-height: 1.4667;
  margin-bottom: 25px;

  @include media('>=tablet') {
    margin: 0 0 37px;
  }

  .title {
    font-size: 20px;
    display: block;
    font-weight: 700;
    color: $blue;
    margin-bottom: 17px;
  }

  .text {
    display: block;
  }

  .head {
    font-weight: 700;
  }

  .top-space {
    margin-top: 14px;
  }

  a {
    text-decoration: none;
  }

  .phone {
    color: $black;
  }
}

.article-wrapper {
  @extend %clearfix;
  position: relative;
  font-size: 16px;
  line-height: 1.625;
  padding: 25px 0 0;

  @include media('>=tablet') {
    padding: 56px 0 3px;
  }

  h1 {
    margin-bottom: 27px;

    @include media('>=tablet') {
      margin-bottom: 31px;
    }

    @include media('<tablet') {
      order: 0;

      + *,
      ~ * {
        order: 2;
      }
    }
  }

  h2 {
    margin-bottom: 33px;
  }

  p {
    margin-bottom: 26px;
  }

  .text-holder {
    @extend %clearfix;

    @include media('>=tablet') {
      padding-top: 7px;
    }

    @include media('<tablet') {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .image-wrap {
    text-align: center;
    margin-bottom: 23px;
    width: 100%;

    @include media('>=tablet') {
      float: right;
      max-width: 48%;
      margin: 7px 0 23px 42px;
    }

    @include media('<tablet') {
      order: 1;
    }
  }

  .figure {
    margin: 0;
  }

  .video-wrap {
    margin: -5px -20px 0;

    @include media('<tablet') {
      order: 3;
      width: calc(100% + 40px);
    }

    @include media('>=tablet') {
      margin: 0 0 0 20px;
      float: right;
      width: 50%;
      padding: 5px 0 20px;
    }
  }

  .video {
    max-width: 560px;
    margin: 0 auto;

    @include media('>=tablet') {
      float: right;
    }

    img {
      width: 100%;
      max-width: none;
    }
  }
}

.table-holder {
  padding: 3px 0 10px;
  position: relative;
  margin: 0 -20px;

  @include media('>=tablet') {
    margin: 0;
    padding: 34px 0 10px;
  }

  @include media('>=sm-tablet') {
    z-index: 9998;
  }

  &.no-top {
    padding-top: 0;
  }
}

.table {
  width: 100%;
  font-size: 14px;

  &.add {
    tbody {
      font-size: 15px;
      line-height: 1.6;

      tr {
        td {
          &:first-child {
            text-transform: uppercase;
            min-width: 145px;
          }

          &:last-child {
            min-width: 150px;
          }
        }
      }
    }

    td {
      a {
        color: #333;
        text-decoration: none;

        &:hover {
          color: $blue;
        }
      }
    }

    .text {
      display: block;
    }

    @include media('<580px') {
      table-layout: fixed;

      tbody {
        font-size: 13px;
        overflow-wrap: break-word;

        tr {
          td {
            &:first-child {
              min-width: 1px;
            }
          }
        }
      }

      th,
      td {
        padding: 8px 5px;
      }
    }
  }

  thead {
    background: #6c9bb5;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    text-align: left;
  }

  th {
    text-transform: uppercase;

    a {
      color:#fff;
    }
  }

  td,
  th {
    padding: 9px 25px;
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #e5edf4;
      }
    }
  }
}

.mobile-table {
  th {
    background: #6c9bb5;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color: $white;
    border-top: 40px solid $white;
  }

  td,
  th {
    padding: 9px 18px;
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: #fff;
      }

      &:nth-child(odd) {
        background: #e5edf4;
      }

      &:first-child {
        th {
          border-top: 0;
        }
      }
    }
  }
}

.design-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 14px 0 0;

  @include media('>=tablet') {
    padding: 62px 0 0;
  }

  h1,h3 {
    font-size: 26px;
    font-weight: normal;
    text-align: center;
    margin: 0 0 31px;

    @include media('>=tablet') {
      font-size: 34px;
      margin-bottom: 28px;
    }
  }

  .main-head {
    padding-bottom: 19px;

    @include media('>=tablet') {
      padding-bottom: 32px;
    }
  }
}

.nav-links {
  @extend %listreset;
  font-size: 16px;
  text-align: center;

  @include media('>=tablet') {
    font-size: 28px;
  }

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 12px;

    @include media('>=tablet') {
      margin: 0 35px;
    }
  }

  a {
    text-decoration: none;
    display: block;
    border: 1px solid rgba(102,102,102,0.5);
    border-radius: 20px;
    color: #333;
    padding: 6px 20px;
    min-width: 280px;
    transition: border 0.3s, background 0.3s, color 0.3s;

    @include media('>=tablet') {
      display: inline-block;
      vertical-align: top;
      position: relative;
      padding: 0 0 16px;
      min-width: 1px;
      background: transparent;
      border: none;
      color: $light-blue;
    }

    &:before {
      @include media('>=tablet') {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 23px;
        height: 5px;
        background: $blue;
        border-radius: 3px;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    &:hover {
      border-color: $blue;
      background: $blue;
      color: $white;

      @include media('>=tablet') {
        background: transparent;
        color: #333;
        border: none;
      }

      &:before {
        @include media('>=tablet') {
          opacity: 1;
        }
      }
    }
  }

  .active {
    >a {
      border-color: $blue;
      background: $blue;
      color: $white;

      @include media('>=tablet') {
        color: $black;
        background: transparent;
        border: none;
      }

      &:before {
        @include media('>=tablet') {
          opacity: 1;
        }
      }
    }
  }
}

.image-description-wrapper {
  position: relative;
}

.image-pins {
  @extend %listreset;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;

  @include media('>=tablet') {
    display: block;
  }

  li {
    position: absolute;
    left: 16%;
    top: 4.7%;
    font-size: 16px;
    line-height: 1.3;

    &:nth-child(2) {
      left: 27.6%;
      top: 16.5%;
    }

    &:nth-child(3) {
      left: 77.9%;
      top: 37.5%;
    }

    &:nth-child(4) {
      left: 36.8%;
      top: 52.9%;
    }

    &:nth-child(5) {
      left: 9.1%;
      top: 87.3%;
    }
  }

  a {
    display: inline-block;
    vertical-align: top;
    background: $blue;
    color: $white;
    text-decoration: none;
    border-radius: 23px;
    padding: 12px 11px 10px 20px;
    transition: background 0.3s;

    &:hover {
      background: $green;
    }
  }
}

.ico-play {
  float: right;
  @include size(24px);
  border: 1px solid $white;
  border-radius: 100%;
  position: relative;
  margin: -1px 0 0 21px;

  &:before {
    content: '';
    position: absolute;
    left: 9px;
    top: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #fff;
  }
}

.article-holder {
  .article-wrapper {
    padding: 26px 0 10px;

    @include media('>=tablet') {
      padding: 48px 0 6px;
    }

    h2 {
      margin-bottom: 16px;

      @include media('>=tablet') {
        margin-bottom: 33px;
      }
    }
  }
}


.description-block {
  &.inner {
    @include media('>=tablet') {
      padding: 79px 0 5px;
    }

    .description-wrapper {
      @include media('>=widescreen') {
        margin: 0 -15px;
      }
    }

    .head {
      overflow: hidden;
      margin: 0 0 11px;

      h2 {
        display: inline-block;
        vertical-align: middle;
        color: $blue;
        font-weight: 700;
        font-size: 26px;
        margin: 20px 0 0;
      }

      .image {
        height: auto;
        display: inline-block;
        vertical-align: middle;
        max-width: 75px;
        margin: 0 0 0 17px;

        @include media('>=tablet') {
          max-width: 95px;
        }

        @include media('>=desktop') {
          max-width: 75px;
        }
      }
    }

    .article {
      max-width: 385px;

      @include media('>=tablet') {
        margin-bottom: 0;
      }
    }
  }
}

.map-search {
  .search-form {
    margin: 10px 0 20px;

    @include media('>=desktop') {
      margin: -25px 0 20px;
    }
  }

  .map-holder {
    margin: 0 -20px;

    @include media('>=tablet') {
      margin: 0 0 26px;
    }
  }
}

.search-form {
  .input-wrap {
    @extend %clearfix;
    width: 267px;
  }

  input[type="text"] {
    width: calc(100% - 68px);
    float: left;
    height: 40px;
    border: 1px solid #b2d4ee;
    background: #f2f6f9;
    color: #666;
    font-size: 16px;
    padding: 4px 15px;

    @include placeholder {
      color: #666;
    }
  }

  .button[type="submit"] {
    width: 68px;
    min-width: 1px;
    float: right;
    height: 40px;
    border-radius: 0 20px 20px 0;
    position: relative;
    padding: 3px;
    left: -1px;
  }
}

.table-wrapper {
  margin: 0 -20px;
  padding-bottom: 10px;

  @include media('>=tablet') {
    padding: 34px 0 18px;
  }

  @include media('>=sm-tablet') {
    overflow: hidden;
  }

  @include media('>=tablet') {
    margin: 0;
  }
}

.alert-wrapper {
  padding: 30px 0;

  @include media('>=tablet') {
    padding: 70px 0 20px;
  }
}

.alert-box {
  background: #6c9bb5;
  padding: 14px 20px 13px 22px;
  color: $white;
  font-size: 16px;
  text-align: center;

  @include media('>=tablet') {
    text-align: left;
  }

  .alert {
    display: block;
    margin: 0 auto 20px;
    @include size(32px);
    border-radius: 32px;
    background: $white;
    color: #6c9bb5;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;

    @include media('>=tablet') {
      display: inline-block;
      vertical-align: top;
      margin: 0 22px 0 0;
    }

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;

    @include media('>=tablet') {
      width: calc(100% - 60px);
    }
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $green;
    }
  }
}

.cart-table-wrapper {
  padding: 20px 0 27px;

  @include media('>=tablet') {
    padding: 20px 0 57px;
  }
}
.cart-table-holder {
  overflow-x: auto;
  margin: 0 -20px;
  padding-bottom: 10px;

  @include media('>=tablet') {
    margin: 0;
  }

  @include media('>=sm-tablet') {
    overflow: hidden;
  }
}

.cart-table {
  width: 100%;
  

  thead {
    background: #6c9bb5;
    color: $white;
  }

  th {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    padding: 9px 25px;
  }

  td {
    padding: 20px 5px;
    border-bottom: 1px solid #ccc;

    &:first-child {
      width: 140px;
    }
  }

  .img {
    float: left;
    width: 140px;
    text-align: center;
  }

  .title {
    font-size: 20px;
    display: block;

    a {
      text-decoration: none;
    }
  }

  .close {
    float: right;
  }

  @include media('<tablet') {
    display: block;
    text-align: center;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }

    td,
    tr {
      display: block;
      position: relative;
      width: auto !important;

      &:before,
      &:after {
        display: none;
      }
    }

    td {
      width: 100%;
      display: block;
      padding: 15px 10px;
      position: relative;
      text-align: center;
      vertical-align: top;
      border-bottom: 1px solid #ccc;

      &:last-child {
        top: 20px;
        width: auto;
        right: 20px;
        border: none;
        position: absolute;
        padding: 0;
      }
    }

    .img {
      float: none;
      display: block;
      max-width: 140px;
      margin: 0 auto;
    }

    .title {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.counter-wrap {
  min-width: 130px;

  .control,
  .input {
    display: inline-block;
    vertical-align: middle;
  }

  .input {
    margin: 0 5px;
  }

  input[type="text"] {
    @include size(50px, 35px);
    border: 1px solid #b2d4ee;
    background: #f2f6f9;
    color: #666;
    text-align: center;
    font-size: 16px;
  }

  .control {
    @include size(24px);
    border-radius: 100%;
    border: 1px solid #cecece;
    color: $blue;
    text-decoration: none;
    font-size: 22px;
    line-height: 23px;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s;

    &:hover {
      background: $green;
      border-color: $green;
      color: $white;
    }

    &:first-child {
      line-height: 19px;
    }
  }
}

.close {
  @include size(30px);
  @include hide-text;
  background: $blue;
  display: block;
  position: relative;
  border-radius: 100%;
  transition: background 0.3s;

  &:hover {
    background: $green;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 20%;
    right: 20%;
    top: 50%;
    height: 2px;
    background: $white;
    transform: rotate(45deg);
    margin-top: -1px;
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.form-block {
  padding: 2px 0 20px;

  .text-wrapper {
    padding-top: 30px;
    font-size: 16px;
    line-height: 1.63;
  }

  p {
    margin-bottom: 25px;
  }

  .image-wrap {
    padding-top: 18px;
  }

  .image {
    text-align: center;
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 24px;
  }
}

.form-text-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: space-between;

  @include media('>=desktop') {
    margin: 0 -20px;
  }

  .col {
    width: 100%;
    padding: 0 10px;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=desktop') {
      width: 46%;
      padding: 0 20px;
    }

    &:first-child {
      @include media('>=desktop') {
        width: 50.8%;
      }
    }
  }
}

.form {
  text-align: left;

  .form-title {
    display: block;
    color: #000;
    font-size: 16px;
    margin-bottom: 8px;
  }

  label {
    font-weight: normal;
  }

  .input-wrap {
    margin-bottom: 22px;
  }

  .form-row {
    @extend %clearfix;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .form-column {
    width: 100%;
    padding: 0 10px;

    @include media('>=tablet') {
      width: 50%;
    }
  }

  .button-wrap {
    text-align: center;

    @include media('>=tablet') {
      text-align: right;
    }
  }

  .button {
    min-width: 280px;

    @include media('>=tablet') {
      min-width: 1px;
    }
  }
}

input.form-input,
textarea.form-input {
  width: 100%;
  height: 40px;
  border: 1px solid #b2d4ee;
  background: #f2f6f9;
  font-size: 16px;
  color: #666;
  transition: border 0.3s;

  &:focus {
    border-color: $blue;
  }
}

textarea.form-input {
  height: 120px !important;
  width: 100% !important;
}

.support-block {
  text-align: center;
  padding: 20px 0 40px;
  font-size: 16px;
  line-height: 1.5;

  @include media('>=tablet') {
    padding: 62px 0 82px;
  }

  .main-head {
    @extend %listreset;
    padding-bottom: 16px;

    @include media('>=tablet') {
      padding-bottom: 41px;
    }
  }

  .container {
    max-width: 683px;
  }

  h2 {
    font-size: 26px;
    font-weight: normal;
    color: $black;
    margin: 0 0 19px;

    @include media('>=tablet') {
      font-size: 36px;
      margin-bottom: 29px;
    }
  }
}
.form.add {
  @include media('>=tablet') {
    .form-row {
      margin: 0 -22px;
    }

    .form-column {
      padding: 0 22px;
      width: 53%;

      &:first-child {
        width: 47%;
      }
    }
  }

  textarea.form-input {
    height: 230px !important;
  }
}

.ico-help {
  @include size(50px);
  display: block;
  margin: 0 auto 26px;
  border: 2px solid #7fa8c8;
  border-radius: 100%;
  font-size: 28px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
  color: $blue;
}