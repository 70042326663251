// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$black: #000;
$white: #fff;
$light-blue: #069;

$blue: #005292;
$green: #95be0e;


$droidsans: 'Droid Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

$base-font-sans-serif: $droidsans;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$base-text-color: $black !default;
$base-background-color: $white !default;
$font-size-base: 15px !default;
$line-height-base: 1.6 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $light-blue;

// Buttons 
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 34px !default;
$h2-font-size: 26px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 18px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: $blue;


$animation-speed: 0.3s;
