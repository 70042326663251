.product-section {
  padding: 24px 0 20px;

  @include media('>=tablet') {
    padding: 92px 0 61px;
  }
}

.main-holder {
  @extend %clearfix;
  margin: 0 -20px;
}

.main-content {
  padding: 0 20px;

  @include media('>=tablet') {
    width: 70%;
    float: right;
  }

  @include media('>=desktop') {
    width: 80%;

    .inner & {
      width: 78.8%;
    }
  }

  .main-head {
    font-size: 26px;
    text-align: center;
    font-weight: normal;
    color: #000;
    margin-bottom: 29px;

    @include media('>=tablet') {
      font-size: 36px;
    }

    .inner & {
      @include media('>=tablet') {
        font-size: 32px;
      }
    }
  }
}

.product-wrapper {
  margin: 0 -10px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  @include media('>=tablet') {
    padding-top: 41px;
  }

  .inner & {
    @include media('>=tablet') {
      padding-top: 11px;
    }
  }

  .column {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    width: 100%;

    @include media('>=tablet') {
      width: 50%;
    }

    @include media('>=desktop') {
      width: 33.33%;
    }
  }
}

.product {
  border: 1px solid #ccc;
  max-width: 280px;
  width: 100%;
  margin: 0 auto 20px;
  padding: 26px 39px 83px;
  position: relative;
  text-align: center;

  @include media('>=tablet') {
    margin-bottom: 39px;
    text-align: left;
  }

  &.has-flag {
    .text-wrap {
      padding-right: 40px;
    }
  }

  .img {
    text-align: center;
    margin: 0 0 33px;
    position: relative;
  }

  h3 {
    font-size: 18px;
    line-height: 1.12;
    margin: 0 0 15px;

    a {
      color: $black;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: $green;
      }
    }
  }

  .btn-wrap {
    position: absolute;
    left: 39px;
    right: 39px;
    bottom: 20px;
  }

  .button {
    width: 100%;
  }

  .flag {
    position: absolute;
    right: 0;
    top: 100%;
    width: 39px;
    margin-top: 73px;
  }
}

.list {
  @extend %listreset;
  font-size: 14px;
  line-height: 1.4286;
}

.accessibility{
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
}

noscript {
  div {
    position: relative;
    z-index: 99;
    text-align: center;
    background: $blue;
    color: $white;
  }
}