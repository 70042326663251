.intranet {
  max-width: 960px;
  margin: 56px auto;

  .filelist-holder {
    overflow: hidden;
    margin: 44px 0;

    .filelist {
      position: relative;
      min-height: 200px;
      border-bottom: 1px solid $esteriBlue;

      em {
        width: 21px;
        display: inline-block;
        margin-right: 8px;
      }

      .path {
        @extend %listreset;
        margin-bottom: 16px;
        position: relative;
        background-color: $esteriBlue;
        padding: 10px 12px 10px 40px;

        li {
          display: inline-block;
          position: relative;

          &:after {
            content: ">";
            margin: 0 6px;
            color: #fff;
          }

          &:first-child:before {
            content: "\f115";
            font-family: "intranet";
            position: absolute;
            top: 0;
            left: -26px;
            color: #fff;
          }

          &:last-child:after {
            content: "";
          }

          a {
            text-decoration: none;
            color: #fff;

            &:hover {
              text-decoration: underline;
            }
          }

          span {
            color: #fff;
          }
        }
      }

      .folder {
        background: #ffffff;
        z-index: 1;
        animation-duration: 1s;
        animation-fill-mode: both;
        position: relative;

        &.in {
          z-index: 1000;
        }

        & > .meta {
          em {
            position: absolute;
            top: 8px;
            left: 16px;
          }

          a {
            transition: none;
            padding: 10px 16px 10px 45px;
            color: #333;
            display: block;
            text-decoration: none;

            &:hover {
              background: $esteriBlue;
              color: #fff;
            }

            p:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .files {
        a {
          position: relative;
          display: block;
          padding: 10px 16px 10px 45px;
          transition: none;
          text-decoration: none;
          color: #333;

          &:hover {
            background: $esteriBlue;
            color: #fff;

            span {
              color: #fff !important;
            }
          }

          em {
            position: absolute;
            top: 8px;
            left: 16px;
          }

          .details {
            float: right;
            text-transform: uppercase;

            span {
              display: inline-block;
              margin-left: 16px;
              color: #333;

              @include media(">tablet") {
                &.date {
                  width: 110px;
                  text-align: right;
                }

                &.size {
                  width: 80px;
                }

                &.type {
                  width: 35px;
                  text-align: center;
                }
              }
            }

            @include media('<=tablet') {
              float: none;

              span {
                font-size: 12px;
                display: inline-block;
                margin: 0 16px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

@font-face {
  font-family: 'intranet';
  src: url('../fonts/intranet.eot?77653315');
  src: url('../fonts/intranet.eot?77653315#iefix') format('embedded-opentype'),
  url('../fonts/intranet.woff2?77653315') format('woff2'),
  url('../fonts/intranet.woff?77653315') format('woff'),
  url('../fonts/intranet.ttf?77653315') format('truetype'),
  url('../fonts/intranet.svg?77653315#intranet') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="intra-icon-"]:before, [class*=" intra-icon-"]:before {
  font-family: "intranet";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.intra-icon-folder:before {
  content: '\f114';
}

.intra-icon-folder-open:before {
  content: '\f115';
}

.intra-icon-folder-open-o:before {
  content: '\e802';
}

.intra-icon-download:before {
  content: '\e801';
}