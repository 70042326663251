.footer {
  background: $blue;
  font-size: 14px;
  line-height: 2.1429;
  color: $white;
  padding: 20px 0;
  text-align: center;

  @include media('>=tablet') {
    padding: 9px 0 12px;
  }

  @include media('>=desktop') {
    text-align: left;
  }

  a {
    color: $white;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: $green;
    }
  }

  address {
    font-style: normal;
    display: inline-block;
    vertical-align: top;
  }

  .tel,
  .email,
  .footer-link,
  .social-link {
    display: block;

    @include media('>=tablet') {
      display: inline-block;
      vertical-align: top;
    }
  }

  .email {
    margin-bottom: 14px;

    @include media('>=tablet') {
      margin-bottom: 0;
    }
  }

  .footer-link,
  .social-link {
    @extend %listreset;
    @extend %clearfix;
    position: relative;
    padding: 16px 0 15px;

    @include media('>=tablet') {
      padding: 0 0 0 24px;
      margin: 0 0 0 23px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 16px;
      background: rgba($white, 0.5);

      @include media('>=tablet') {
        width: 1px;
        top: 8px;
        bottom: 5px;
        left: 0;
        height: auto;
        transform: none;
      }
    }

    li {
      @include media('>=tablet') {
        float: left;
        margin: 0 18px 0 0;
      }
    }
  }

  .tel,
  .email {
    @include media('>=tablet') {
      margin-left: 15px;
    }
  }

  .social-link {
    @include media('>=tablet') {
      margin-left: 3px;
    }
  }
}

.footer-holder {
  @include media('>=widescreen') {
    float: left;
  }

  .hide-desktop {
    @include media('>=tablet') {
      display: none;
    }
  }

  .gap {
    display: none;

    @include media('>=tablet') {
      display: inline;
      margin: 0 -3px;
    }
  }
}

.siteby {
  display: block;
  font-size: 12px;
  padding-top: 24px;
  text-align: center;

  @include media('>=tablet') {
    padding-top: 10px;
  }

  @include media('>=widescreen') {
    float: right;
    margin-top: 3px;
    padding-top: 0;
  }
}