html,
body {
  height: 100%;
}

body {
  min-width: 320px;
}

#wrapper {
  overflow: hidden;
  width: 100%;
  padding-top: 94px;

  @include media('>=desktop') {
    padding-top: 110px;
  }
}

.container {
  @extend %clearfix;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.bg-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  img {
    visibility: hidden;
  }
}

img {
  vertical-align: top;
}

a {
  transition: color 0.3s;
  &:hover {
    color: $green;
  }
}

.button {
  display: inline-block;
  vertical-align: top;
  background: $white;
  color: $blue;
  border-radius: 25px;
  font-size: 16px;
  text-decoration: none;
  padding: 13px 20px;
  min-width: 150px;
  text-align: center;
  border: none;
  transition: background 0.3s, color 0.3s, border 0.3s;

  .arrow {
    display: inline-block;
    vertical-align: top;
    margin: 0 -10px 0 14px;

    .icon-arrow-down {
      position: relative;
      top: 1px;
      font-size: 12px;
    }
  }

  &.blue {
    background: $blue;
    color: $white;
    padding: 7px 26px;

    &:hover {
      background: $green;
      color: $white;
    }
  }

  &.green {
    background: $green;
    color: $white;
    padding: 7px 26px;

    &:hover {
      background: $blue;
      color: $white;
    }
  }

  &.style1 {
    border-radius: 5px 0 0 5px;
    padding: 19px 22px;
    text-align: left;
    position: relative;

    @include media('>=tablet') {
      font-size: 20px;
      font-weight: 700;
      min-width: 343px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 32px 0 31px 20px;
      border-color: transparent transparent transparent $white;
      transition: border 0.3s;

      @include media('>=tablet') {
        border-width: 35px 0 35px 20px;
      }
    }

    &:hover {
      background: $green;
      color: $white;

      &:before {
        border-left-color: $green;
      }
    }
  }

  &.plain {
    border: 1px solid #7fa8c8;
    background: transparent;
    padding: 4px 26px;
    min-width: 200px;

    &:hover {
      border-color: $green;
      background: $green;
      color: $white;
    }
  }

  &:hover {
    background: $green;
    color: $white;
  }
}

.link {
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-size: 26px;

  @include media('>=tablet') {
    font-size: 34px;
  }
}

// hide tel links on desktop
@media only screen and (min-width:1025px) {
    a[href^=tel],a[href^=skype], a[href^=sms] {
    cursor:default;
    pointer-events:none;
  }
}

.js-tab-hidden {
  @include media('>=tablet') {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
  }
}