$esteriBlue: #005292;
$white: #fff;


.language-hold  {
    .language-wrap {
        @include media('<=tablet') {
            opacity: 1;
            visibility: visible;
            .language-list:nth-child(odd) {
                margin-left: 4px;
            }
        }
    }
}

.menu-hold {
    @include media('<=tablet') {
        width: 100%;
    }
}

.login {
  form {
    fieldset {
      width: 100% !important;
      padding: 0 15px 0 0 !important;

      button.btn[type=submit] {
        float: none;
        text-decoration: none;
        display: inline-block;
        vertical-align: top;
        border: none !important;
        color: #fff;
        background: $esteriBlue;
        padding: 5px 10px;
        font-size: 15px;
        line-height: 24px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
    }
  }

  .links {
    margin: 0;
    padding: 0;
  }

  .control-group {
    label {
      font: 16px/24px "fira-sans", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    }

    &.field-submit {
      .controls {
        margin-left: 0px;
      }
    }

    &.field-username,
    &.field-password {
      .control-label {
        float: none;

        label {
          text-align: left;
        }
      }
      .controls {
        margin-left: 0px;

        input[type=text], input[type=password] {
          font: 16px/24px "fira-sans", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
          box-shadow: none;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          -webkit-appearance: none;
          border: 1px solid #999;
          padding: .4em .7em;
          width: 300px;

          @include media ('<=phone') {
            width: 100%;
          }
        }
      }
    }
  }
}

/*<div class="login">
   <form action="/kirjaudu?task=user.login" method="post" class="form-validate form-horizontal well">
      <fieldset>
         <div class="control-group">
            <div class="control-label">
               <label id="username-lbl" for="username" class="required">
               Käyttäjätunnus<span class="star">&nbsp;*</span></label>
            </div>
            <div class="controls">
               <input type="text" name="username" id="username" value="" class="validate-username required" size="25" required="required" aria-required="true" autofocus="" aria-invalid="false">						
            </div>
         </div>
         <div class="control-group">
            <div class="control-label">
               <label id="password-lbl" for="password" class="required">
               Salasana<span class="star">&nbsp;*</span></label>
            </div>
            <div class="controls">
               <input type="password" name="password" id="password" value="" class="validate-password required" size="25" maxlength="99" required="required" aria-required="true">						
            </div>
         </div>
         <div class="control-group">
            <div class="control-label"><label>Muista minut</label></div>
            <div class="controls"><input id="remember" type="checkbox" name="remember" class="inputbox" value="yes"></div>
         </div>
         <div class="control-group">
            <div class="controls">
               <button type="submit" class="btn btn-primary">
               Kirjaudu					</button>
            </div>
         </div>
         <input type="hidden" name="return" value="L2luZGV4LnBocA==">
         <input type="hidden" name="8eb9a52447bea62695bc620e6eb7f328" value="1">		
      </fieldset>
   </form>
</div>*/

.support-block {
    @include media('>=tablet') {
        padding: 27px 0 82px;
    }
}

div.login {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    margin-top: 50px;
    form {
        .control-label label {
            text-align: left;
        }
        .controls  {
            .input:-webkit-autofill {
                background: #fff;
            }
            input[type='password'], input[type='text'] {
                width: 200px;
                background-color: #fff none;
                border: solid 1px #ccc;
                outline: none;
                padding: 6px 12px;
                border-radius: 4px;
                -moz-border-radius: 4px;
                -webkit-border-radius: 4px;
                margin: 0;
                text-align: left;
            }
        }
    }
}

.button.blue {
    outline: none;
}

.contact-description .btn-hold {
    @include media('<=phone') {
        text-align: center;
    }
}

.other-login-settings {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    text-align: left;
    margin-bottom: 50px !important;
    .nav-tabs {
        margin: 0;
        li {
            display: inline;
            a {
                text-decoration: none;
            }
        }
    }
}

.contact-wrapper {
    padding: 8px 0 0px;
}

.pswp {
    z-index: 9999 !important;
}

.right-column {
    .widget {
        a {
            cursor: pointer;
        }
    }
}

.product-about {
    padding: 27px 0 27px;
    @include media('>=tablet') {
        padding: 5px 0 0;
    }
}

.aside-info {
	&:hover {
		color: initial;

		a {
            &:hover {
                color: #000 !important;
            }
			color: $blue;
		}
        .ico-help.no-hover {
			border-color: initial;
			background: initial;
			color: initial;
		}
	}
}

.description-text-holder .column-holder {
    @include media('<=tablet') {
        padding: 15px 0 10px;
    }
}

.product-tile {
    padding: 20px 0 0;
}

.description-text-holder .left-column img {
    margin: 15px 0;
}

.description-text-holder p {
    
}

.table.add {
    @include media('>=phone') {
        margin-bottom: 50px;
    }
}

.banner.with-video {
    min-height: 0;

    .bg-img {
        display: block;
    }

    .bg-img.background-video {
        display: none;
    }
    @include media('>=phone') {
        padding: 0;
    }
    @include media('>=phone') {
        .bg-img.background-video {
            display: block;
        }
        .bg-img {
            display: none;
        }
    }
    .container.has-background-video {
        position: absolute;
        left: 0;
        right: 0;
        top: 10%;
        @include media('<=phone') {
            position: static;
        }
    }
}

.bg-img.background-video {
    width: 100%;
    height: 100%;

    max-width: none;
    vertical-align: middle;
    position: relative;

    min-width: 1200px;
}

#bodyContent {
    text-align: left;
    p {
        line-height: 0.6em;
    }
}

.main-content {
    h3 {
        margin-bottom: 20px;
        font-weight: normal;
    }
}

.table.add td a {
    color: $esteriBlue;
}

.text.city {
    text-transform: uppercase;
}

.list-box li {
    font-size: 0.95em;
    padding-left: 0;
    &::before {
        content: none;
    }
}

.alert-wrapper {
    padding-top: 20px;
}

.small-table {
    margin-bottom: 0;
}

.sl-modal-holder .sl-modal.iframe-modal {
    width: auto;
    max-width: none;
    width: 90%;
    max-width: 768px;
}

.sl-modal-holder .sl-modal .sl-inner .sl-body.sl-iframe-body {
    margin: 0;
    width: 100%;
    height: auto;
}

#pin-iframe {
    width: 100%;
    height: 100%;
    min-height: 420px;
}

.sl-modal-holder {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;

    .sl-modal-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8); 
    }
    .sl-modal {
        width: 90%;
        max-width: 600px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        -moz-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);

        .sl-inner {
            background: #ffffff;
            box-shadow: 0 0 8px #999;
            border: 1px solid #eee;
            border-radius: 3px;
            padding: 16px;

            .sl-head {
                h3 {
                    font-size: 22px;
                }
                .close {
                    top: -15px;
                    z-index: 50;
                    width: 30px;
                    height: 30px;
                    right: -15px;
                    overflow: hidden;
                    line-height: 27px;
                    position: absolute;
                    text-align: center;
                    border-radius: 100%;
                    border: 1px solid $esteriBlue;
                    background-color: $white;
                    transition: background-color .3s linear;
                    &:before,
                    &:after {
                        content: '';
                        width: 17px;
                        height: 2px;
                        display: block;
                        position: absolute;
                        background-color: $esteriBlue;
                        transition: background-color .3s linear;
                    }
                    &:hover {
                        background-color: $esteriBlue;
                        &:before,
                        &:after {
                            background-color: $white;
                        }
                    }
                }

            }

            .sl-body {
                margin: 16px 0; 
            }

            p:last-child {
                margin-bottom: 0; 
            }

            .sl-footer {
                text-align: right; 

                .btn {

                    @include media('<=phone') {
                        margin-top: 3px ;
                    }

                    margin-left: 16px;
                    padding: 10px;
                    background: $esteriBlue;
                    color: #fff;
                    text-decoration: none;
                    white-space: nowrap;
                    display: inline-block;
                    transition: background-color .3s linear;
                }
                .btn:hover {
                    background: #000;
                }
            }
        }
    }
}

.video-wrap {
    .video {
        padding: 20px;
        width: 100%;
        iframe {
            width: 100%;
            max-width: 100%;
        }
    }
}

#google-map {
    width: 100%;
}
.contact-map {
    height: 300px !important;
}

.maintenance-map {
    height: 600px;
}

form.form.add {
    input.button.blue {
        border-radius: 20px;
        padding: 9px 20px;
        min-width: 100px;
        @include media('<=tablet') {
            min-width: 280px;
        }
    }
}


.contact-detail-wrap {
     @include media('>=tablet') {
        text-align: left;
    }
    .column {
        @include media('<=phone') {
            float: left;
        }
    }
}

.table.add th, .table.add td {
    @include media('<=tablet') {
        padding: 10px;
    }
}

.main-holder {
    .main-content {
        div.pagination {
            ul {
                padding: 0;
                text-align: center;
                li {
                    margin: 0 auto;
                    padding: 0 10px;
                    display: inline-block;
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.contact-detail-wrap .column {
    @include media('<=tablet') {
        width: 50%;
        float: left;
        height: 271px;
        overflow: hidden;
    }
    @include media('<=phone') {
        float: none;
        width: 100% !important;
    }
}

.breadcrumbs {
    list-style: none;
    padding: 0;
}