.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  height: 94px;
  padding: 20px 0;
  background: $white;
  min-width: 320px;

  @include media('>=tablet') {
    position: fixed;
  }

  @include media('>=desktop') {
    height: 110px;
    padding-top: 26px;
    transition: padding 0.3s, height 0.3s;

    .fixed-header & {
      height: 57px;
      padding-top: 7px;
      box-shadow: 0 1px 1px rgba(0,0,0,.2);
    }
  }
}

.logo {
  float: left;
  width: 145px;
  position: relative;
  z-index: 2;

  @include media('>=desktop') {
    width: 165px;
  }

  .logo-text {
    display: block;
    font-size: 12px;
    color: $black;
    white-space: nowrap;
    margin: 3px 0 0 -1px;

    @include media('>=desktop') {
      font-size: 14px;
      margin: 5px 0 0 -2px;
      transition: font 0.3s;

      .fixed-header & {
        font-size: 10px;
      }
    }
  }

  img {
    width: 131px;

    @include media('>=desktop') {
      width: 153px;
      transition: width 0.3s;

      .fixed-header & {
        width: 106px;
      }
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
  }
}

.header-holder {
  @extend %clearfix;
  float: right;
  padding: 18px 0 0;

  @include media('>=desktop') {
    padding-top: 11px;
    position: relative;
    float: none;
    transition: padding 0.3s;

    .fixed-header & {
      padding-top: 3px;
    }
  }
}

.navigation {
  float: right;
  font-size: 18px;

  @include media('>=desktop') {
    float: left;
    padding: 4px 0 0 20px;
  }

  @include media('>=widescreen') {
    padding-left: 42px;
  }
}

.nav-button {
  display: block;
  width: 30px;
  height: 19px;
  position: relative;

  @include media('>=desktop') {
    display: none;
  }

  .nav-active .header & {
    span {
      opacity: 0;
    }

    &:before,
    &:after {
      top: 50%;
      bottom: auto;
      left: 2%;
      right: 2%;
      transform: rotate(45deg);
      background: #666;
      margin-top: -1px;
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  span,
  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 3px;
    background: $blue;
    margin-top: -1.5px;
    transition: all 0.3s;
  }

  &:before,
  &:after {
    content: '';
    top: 0;
    margin: 0;
  }

  &:before {
    top: auto;
    bottom: 0;
  }
}

.nav-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: $blue;
  padding-bottom: 11px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

  @include media('<desktop') {
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateY(20px);
  }

  @include media('>=desktop') {
    position: static;
    transform: none;
    overflow: visible;
    opacity: 1;
    visibility: visible;
    background: transparent;
  }

  .nav-active & {
    opacity: 1;
    visibility: visible;

    @include media('<desktop') {
      transform: translateY(0);
    }
  }
}

.main-nav {
  @extend %listreset;
  @extend %clearfix;
  padding: 13px 0;

  @include media('>=desktop') {
    padding: 0;
  }

  li {
    position: relative;

    @include media('>=desktop') {
      float: left;
      padding: 0 17px 0 0;

      &.has-drop-down {
        padding-bottom: 31px;

        .fixed-header & {
          padding-bottom: 5px;
        }
      }

      &:hover {
        >a {
          &:before {
            opacity: 1;
          }
        }

        >.nav-drop {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }

    @include media('>=widescreen') {
      padding-right: 25px;
    }

    @include media('>=largescreen') {
      padding-right: 33px;
    }
  }

  .js-acc-hidden {
    @include media('>=desktop') {
      left: 0 !important;
      top: 100% !important;
    }
  }

  a {
    display: block;
    color: $white;
    text-decoration: none;
    padding: 5px 50px 5px 20px;
    transition: color 0.3s;
    position: relative;

    &:hover {
      color: #b0d7ed;
    }

    @include media('>=desktop') {
      padding: 0 0 9px;
      color: $black;

      &:before {
        content: '';
        width: 23px;
        height: 5px;
        border-radius: 3px;
        background: $blue;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        color: $black;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  .hold {
    position: relative;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    text-align: center;
    padding: 0;
    cursor: pointer;
    color: $white;
    font-size: 26px;
    line-height: 1;
    padding: 6px 11px 6px 2px;

    @include media('>=desktop') {
      display: none;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .nav-drop {
    font-size: 16px;
    padding: 1px 0 9px;

    @include media('>=desktop') {
      position: absolute;
      left: 0;
      top: 100%;
      width: 220px !important;
      background: $blue;
      z-index: 9;
      margin-left: -72px;
      padding: 13px 0 20px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
      transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    }

    ul {
      @extend %listreset;
      @extend %clearfix;
    }

    li {
      @include media('>=desktop') {
        float: none;
        padding: 0;
      }
    }

    a {
      padding: 2px 50px 2px 40px;

      @include media('>=desktop') {
        padding: 2px 10px 2px 20px;
        color: $white;

        &:hover {
          color: #b0d7ed;
        }
      }
    }

    .nav-drop {
      font-size: 15px;
      padding: 2px 0 6px;

      @include media('>=desktop') {
        padding: 13px 0 20px;
        margin-left: 0;
        left: 100% !important;
        top: 0 !important;
      }

      a {
        padding: 3px 50px 3px 60px;

        @include media('>=desktop') {
          padding: 2px 10px 2px 20px;
        }
      }
    }
  }

  .active {
    >a {
      color: #b0d7ed;

      @include media('>=desktop') {
        color: #005292;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  .slide-active {
    >a {
      >.arrow {
        padding: 6px 14px 6px 7px;

        &:before {
          content: '-';
        }

        span {
          opacity: 0;
          display: none;
        }
      }
    }
  }

  .nav-drop {
    .slide-active {
      >a {
        >.arrow {
          line-height: 0.8;
        }
      }
    }
  }
}

.cart-holder {
  float: left;
  margin-left: 9px;
  font-size: 16px;

  @include media('>=desktop') {
    margin-left: 0;
    float: right;
  }

  .cart-btn {
    display: block;
    text-decoration: none;
    color: #6c9bb5;
    position: relative;
    font-size: 23px;
    line-height: 1;
    margin: 1px 0 0;
    transition: color 0.3s;

    &:hover {
      color: $blue;

      .num {
        background: $green;
      }
    }

    @include media('>=desktop') {
      margin-top: 5px;
    }

    @include media('>=widescreen') {
      padding: 9px 6px 10px 15px;
      border: 1px solid #999;
      border-radius: 18px;
      color: $black;
      font-size: 16px;
      margin-top: 0;
      min-width: 130px;
    }

    .text {
      display: none;

      @include media('>=widescreen') {
        display: inline-block;
      }
    }

    .ico {
      @include media('>=widescreen') {
        display: none;
      }
    }
  }

  .num {
    @include size(17px);
    border-radius: 100%;
    position: absolute;
    right: -10px;
    top: -7px;
    background: $blue;
    text-align: center;
    color: $white;
    font-size: 9px;
    font-weight: 700;
    line-height: 17px;
    transition: background 0.3s;

    @include media('>=widescreen') {
      @include size(23px);
      position: static;
      float: right;
      font-size: 12px;
      line-height: 23px;
      font-weight: 700;
      border-radius: 100%;
      margin: -3px 0 0 13px;
      color: $white;
      text-align: center;
    }
  }
}

.header-search-form {
  float: right;
  position: relative;
  margin: -4px 17px 0 27px;

  @include media('>=desktop') {
    float: right;
    margin: 5px 50px 0 29px;
  }

  @include media('>=widescreen') {
    margin-right: 65px;
  }

  .search-opener {
    text-decoration: none;
    color: #666;
    display: block;
    font-size: 23px;
    line-height: 1;
    transition: color 0.3s;

    &:hover {
      color: $blue;
    }

    .search-active & {
      color: $blue;
    }
  }

  .search-wrap {
    position: absolute;
    top: 0;
    right: 100%;
    width: 203px;
    height: 37px;
    margin: -2px 10px 0 0;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transform: translateX(20px);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    @include media('>=tablet') {
      margin: -5px 11px 0 0;
      width: 230px;
    }

    .search-active & {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  input[type="text"],
  input[type="search"] {
    width: 100%;
    height: 37px;
    border: 1px solid #b1d3ed;
    background: #f1f5f9;
    color: #666;
    font-size: 16px;
    transition: border 0.3s;

    &:focus {
      border-color: $blue;
    }

    @include placeholder {
      color: #666;
    }
  }
}

.language-hold {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 10px 20px;
  font-size: 15px;

  @include media('>=desktop') {
    position: absolute;
    right: 0;
    top: 0;
    transition: top 0.3s;
    margin-right: 0;

    .fixed-header & {
      top: -7px;
    }
  }

  @include media('>=widescreen') {
    margin-right: 20px;
  }

  a {
    @include size(37px);
    display: block;
    border: 1px solid $white;
    text-align: center;
    color: $white;
    text-align: center;
    padding: 6px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s, border 0.3s, background 0.3s;

    &:hover {
      border-color: #b0d7ed;
      color: #b0d7ed;
    }

    @include media('>=desktop') {
      border-color: #999;
      color: $black;

      &:hover {
        background: $blue;
        border-color: $blue;
        color: $white;
      }
    }
  }

  .language-wrap {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    opacity: 0;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;

    .language-active & {
      opacity: 1;
      visibility: visible;
    }

    @include media('>=desktop') {
      margin: -1px 0 0;
      left: 0;
      top: 100%;
      bottom: auto;
    }
  }
}

.language-list {
  @extend %listreset;
}